@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: #333;
}
#background_wrap {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-image: url('images/background3.webp');
}
.pointer {
  cursor: pointer;
}
.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text-center {
  text-align: center;
}
.aggregateButtonContainer {
  margin-bottom: 16px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.button {
  background-color: #6A1B9A;
  color: #fff;
  border: none;
  font-size: 16px;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 16px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttonDisabled {
  background-color: #dad9f3;
  color: #333;
  font-size: 16px;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px 16px;
  margin-bottom: 8px;
  cursor: not-allowed;
}

.button:hover {
  background-color: #dad9f3;
}

.button-row {
  display: flex;
  gap: 10px;
  margin-top: 8px;
}

.button-sm {
  background-color: #dad9f3;
  color: #333;
  border: none;
  font-size: 12px;
  border-radius: 2px;
  padding: 2px 4px;
  margin-bottom: 2px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* ArticleStyles.css */
h4 {
    margin-top: 6px;
    margin-bottom: 6px;
}

p {
    white-space: pre-line;
}
.article-container {
  width: 35%;
  margin-bottom: 50px;
}
.socialButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4px;
}

.socialButton {
  width: 80px;
  max-width: 25%;
}

.flip-container {
  perspective: 1000px;
  height: auto;
  position: relative;
}

/* Flipper does the flipping */
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  width: 100%;
  height: auto; /* Adjust based on content */
}

/* Front and back styling */
.front, .back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  backface-visibility: hidden; /* Hide the side not facing the screen */
}

/* Ensure back content is correctly oriented and centered */
.back {
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccd7d9;
  border-radius: 8px;
}

/* Flipped state */
.flip-container.flipped .flipper {
  transform: rotateY(180deg);
}

.mainContainer {
  display: flex;
  flex-direction: column;
  background-color: #ccd7d9;
  border-radius: 8px;
}

.row {
  display: flex;
  flex-direction: row;
  padding: 10px 10px;
}
.socials-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.column-sm {
  width: 30%;
  padding: 6px;
}
.column-bg {
  width: 70%;
}

.imageLink {
  overflow: hidden;
  display: block; /* Keeps existing styling, or adjust as needed */
  position: relative; /* Ensures absolute positioning is relative to this container */
  border-radius: 4px;
}

.imageLink a {
  width: 100%;
  padding-bottom: 75%; /* 4:3 aspect ratio (e.g., 300px by 225px) */
  overflow: hidden;
  position: relative; /* Position relative for absolute child elements */
  display: block; /* Block display to fill parent dimensions */
  text-decoration: none; /* Remove text decoration */
  border-radius: 4px;
  transition: transform 0.3s ease-in-out;
}

.imageLink img {
  position: absolute;
  width: 100%; /* Full width of the container */
  height: 100%; /* Full height of the container */
  border-radius: 4px;
  object-fit: cover; /* Cover the area, clipping the excess if necessary */
  object-position: center; /* Center the image within the container */

}

.imageLink a:hover {
  transform: scale(1.03); /* Slight zoom effect on hover */
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Shadow effect for depth */
}

.flexOverlayContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2; /* Ensure it's above the image */
}

.imageLinkOverlay {
  /* Remove absolute positioning and related properties */
  color: white;
  font-size: 12px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 2px 4px;
  border-radius: 4px;
  text-shadow: 1px 1px 2px black;
  margin-bottom: 10px;
  /* Flex item, can be centered via the flex container */
}

.imageLinkOverlay:before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAQAAAAnOwc2AAAAQ0lEQVQI122Nyw3AIAzFHNRBGI3uP4R7oIhXUV+iOJ9XBDpr8wWgivszBXCoQ0NOBSGXinN7qP3THn0GLdn44Tp34QFyyjQcMhJ1zwAAAABJRU5ErkJggg==);
  margin: 0px 5px 0px 0px;
}

.graphContainer {
  display:flex;
  align-items: center;
  justify-content: space-evenly;
}

.pie-chart {
  width: 25%; /* Ensure the pie chart fills its container */
}

.bottom-line {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}



/* popup */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.popup-content {
  height: 95%;
}

.popup-inner {
  position: relative;
  perspective: 1000px;
  background: none;
  padding: 10px;
  border-radius: 4px;
  background-color: #f4f4f4;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 700px;
  height: 85vh;
  font-family: 'Roboto', 'Arial', sans-serif; /* Improved font */
}

.post-send {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  margin: 0 4px;
  transition: background-color 0.3s, box-shadow 0.3s, border 0.3s;
  color: #fff;
  background-color: #3700b3; /* Example primary color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24), 0 0 1px rgba(0, 0, 0, 0.24);
}

.post-disabled {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  display: inline-block;
  margin: 0 4px;
  transition: background-color 0.3s, box-shadow 0.3s, border 0.3s;
  color: #fff;

  background-color: #9E9E9E; /* Greyed out */
  cursor: not-allowed;
}

.disabled {
  cursor: not-allowed;
}

.date-navigation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 20px; /* Adjust based on your layout */
  background: transparent; /* Match your theme */
  border-radius: 8px;
}

.date-nav-button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #6200ea; /* Your primary color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.date-nav-button:hover {
  background-color: #3700b3; /* Darker shade for hover state */
}

.date-nav-button:disabled {
  background-color: #9E9E9E; /* Disabled state */
  cursor: not-allowed;
}

.date-display {
  padding: 10px 20px;
  background-color: #f0f0f0; /* Light background for contrast */
  border-radius: 4px;
}

.returned-text {
  margin-top: 20px;
  padding: 10px;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
  height: 30vh;
}

.returned-text h3 {
  margin-top: 0;
}

.returned-text p {
  white-space: pre-wrap; /* Ensures formatting and spacing are preserved */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 4px solid rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s infinite linear;
}


.popup-inner input[type="file"] {
  margin-top: 4px;
}

.close-button {
  position: absolute;
  top: -15px; /* Position it slightly outside the top boundary */
  right: -15px; /* Position it slightly outside the right boundary */
  width: 30px; /* Equal width and height for a circle */
  height: 30px;
  border-radius: 50%; /* Full border-radius for a perfect circle */
  line-height: 30px; /* Aligns the 'x' vertically */
  text-align: center;
  background-color: white; /* White background */
  color: black; /* Black 'x' character */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  cursor: pointer;
  border: none; /* No border */
  font-size: 24px; /* Size of the 'x' character */
}

.image-preview {
  position: relative;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px; /* Adjust as needed */
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
}

.no-image {
  color: #333;
  text-align: center;
}

.image-generation {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textarea-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.0);
}

.textarea-container textarea {
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}
.textarea-container textarea::-webkit-scrollbar {
  width: 8px;
}
.textarea-container textarea::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.textarea-container textarea::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 4px;
}


.refresh-button {
  position: absolute;
  bottom: 0px; /* Adjust the positioning as needed */
  left: 0px;
  background: transparent;
  border: none;
  z-index: 2000;
  padding: 5px; /* Adjust for padding */
}
.refresh-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.refresh-button img {
  width: 24px; /* Adjust the size of the icon as needed */
  height: 24px;
}

.left-button {
  display: flex; /* Align children inline */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  gap: 8px; /* Space between icon and text */
  position: absolute;
  top: 10px; /* Adjust the positioning as needed */
  left: 10px;
  border: none;
  padding: 4px; /* Adjust for padding */
  margin-bottom: 8px;
}
.left-button img {
  width: 24px; /* Adjust the size of the icon as needed */
  height: 24px;
}
.right-button {
  display: flex; /* Align children inline */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  gap: 8px; /* Space between icon and text */
  position: absolute;
  top: 10px; /* Adjust the positioning as needed */
  right: 10px;
  border: none;
  padding: 4px; /* Adjust for padding */
  margin-bottom: 8px;
}
.right-button img {
  width: 24px; /* Adjust the size of the icon as needed */
  height: 24px;
}

@keyframes clickAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15); /* Increase the scale for emphasis */
  }
  100% {
    transform: scale(1);
  }
}

.animated {
  animation: clickAnimation 0.4s ease;
}
.button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.generation-button {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  padding: 8px 16px;
  background-color: #6200ea;
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24), 0 0 1px rgba(0, 0, 0, 0.24);
}

.generation-button:hover {
  background-color: #3700b3;
}
.generation-button:disabled {
  cursor: not-allowed;
  color: #fff;
  background-color: #9E9E9E; /* Greyed out */
}

.post-refresh-button {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  background: transparent;
  border: none;
  cursor: pointer;
}

.copy-button {
  position: absolute;
  bottom: 0px; /* Align with the refresh button */
  left: 34px; /* Adjust based on the size of the refresh button */
  background: transparent;
  border: none;
  padding: 5px; /* Adjust for padding */
}

.copy-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.copy-button img {
  width: 24px; /* Match the refresh icon size */
  height: 24px;
}
.meme-refresh-button {
  position: absolute;
  bottom:0;
  left:0;
  z-index: 10;
}
.meme-refresh-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.meme-refresh-button img {
  width: 24px; /* Adjust the size of the icon as needed */
  height: 24px;
  background-color: rgba(255, 255, 255, 0.7);
}

.hashtags-container {
  position: relative;
}

.hashtags-container textarea {
  width: 100%;
  height: 50px;
  margin-top: 4px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: vertical;
  background-color: #e8eaf6; /* Light background color for the textarea */
}
.char-count {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-color: rgba(255, 255, 255, 0.7); /* Slightly transparent background */
  padding: 0 4px;
  border-radius: 4px;
  color: #333;
  font-size: 0.8em;
}

.inputCard {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 100%;
  position: relative;
  box-sizing: border-box;
  transition: box-shadow 0.3s ease;
  overflow: hidden; /* Ensures content fits within the card */
  background-size: cover;
  background-position: center;
}
.inputAlteredCard {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  height: 77vh;
  position: relative;
  box-sizing: border-box;
  transition: box-shadow 0.3s ease;
  overflow: hidden; /* Ensures content fits within the card */
  background-size: cover;
  background-position: center;

}
.memeCard {
  background-color: #fff;
  margin: 10px;
  width: 150px;
  height: 150px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  overflow: hidden; /* Ensures content fits within the card */
  background-size: cover;
  background-position: center;
}

.inputCard:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
.inputCard.textArea {
  margin-top: 0 !important;
}
.inputCard-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: rgba(255, 255, 255, 0.9); /* Semi-transparent overlay */
}

.memeCard-content {
  background: rgba(255, 255, 255, 0.9); /* Semi-transparent overlay */
}
.postTextSection {
  flex-grow: 1;
  margin: 20px;
}

.inputCard-content h3 {
  font-size: 1.2em;
  color: #333;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  text-align: center;
  margin: 0;
  font-weight: bold;
}

.inputCard-content p {
  font-size: 1em; /* Adjust font size for additional text */
  color: #555;
  text-align: center;
}

.inputCard-content-overflow {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}
.inputCard-content::-webkit-scrollbar {
  width: 8px;
}
.inputCard-content::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.inputCard-content::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 4px;
}

.memeCard.memeGenerator {
  background-image: url('../images/meme_generator.webp');
}
.inputCard.textArea {
  margin-top: 40px;
  background-image: url('../images/text_summary.png');
}
.inputAlteredCard.textArea {
  background-image: url('../images/text_summary.png');
}
.inputCard.mailGenerator {
  background-image: url('../images/mail_generator.webp');
}

.inputCard.hashtagGenerator {
  background-image: url('../images/hashtag_generator.webp');
}
.inputCard.middle {
  background-image: url('../images/text_analysis.png');
}
.inputCard.blogGenerator {
  background-image: url('../images/blog_generator.webp');
}
.hashtag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding: 4px;
}
.hashtag-item {
  cursor: pointer;
  background-color: #eee;
  border-radius: 5px;
  padding: 2px 4px;
  transition: background-color 0.3s ease;
}
.hashtag-item:hover {
  background-color: #ddd;
}
.hashtag-included {
  background-color: #cacaca;
  color: white;
}



.topic-header-container {
  margin: 0 auto;
  width: 25%;
  position: relative;
  display: flex;
  justify-content: center; /* Centers the headline */
  align-items: center;
  margin-bottom: 40px;
}

.topic-headline {
  /* Ensure the headline is centered */
  text-align: center;
  width: 100%; /* Take full width to ensure centering */
  margin: 0; /* Adjust as necessary */
}


.filter-icon-button {
  background: none;
  border: none;
  cursor: pointer;
}

.filter-icon-button img {
  height: 1.5em; /* Adjust based on your icon size */
  width: auto;
}

.sort-options {
  position: absolute;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  list-style-type: none; /* Remove default list item bullets */
  padding: 0;
  width: 250px;
  margin-top: 5px; /* Small gap between the button and the options */
  z-index: 20; /* Ensure it appears above other content */
}

.sort-options li {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s; /* Smooth background color change on hover */
}

.sort-options li:hover {
  background-color: #f0f0f0; /* Light grey background on hover */
}

.generatorSummaryField {
  height: 15vh !important;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f1f1f1; /* Neutral background color */
  padding: 0;
  height: 32px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Adds a subtle shadow for depth */
}

.tabs-container {
  display: flex;
  overflow-x: auto;
  height: 100%;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
  white-space: nowrap;
  flex-grow: 1;
}

.tab-button {
  box-sizing: border-box; /* Include padding and border in the button's total size */
  height: 100%; /* Make the buttons take up the full height of the header */
}

.tab-active,
.tab-inactive {
  padding: 4px 8px;
  cursor: pointer;
  border: none;
  background: transparent;
  color: #495057;
  margin-right: 10px;
  transition: all 0.3s ease;
  font-weight: 600;
  text-transform: uppercase;
  outline: none;
  border-radius: 20px;
}

/* Styles for inactive tabs */
.tab-inactive {
  background: #e8e8e8;
  color: #6c757d; /* Muted text color */
}

/* Styles for active tab */
.tab-active {
  background: #3700b3;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Hover styles for inactive tabs */
.tab-inactive:hover {
  color: #495057;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1100px) and (min-width: 700px) {
  .articleContainer {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
  }
  .article-container {
    width: 75%;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 700px) {
  .generation-button {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    padding: 6px 10px;
    background-color: #6200ea;
    color: white;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24), 0 0 1px rgba(0, 0, 0, 0.24);
  }
  .inputCard.textArea {
    margin-top: 20px;
  }
  .generatorSummaryField {
    height: 20vh !important;
  }
  .right-button {
    top: 0px; /* Adjust the positioning as needed */
  }
  .left-button {
    top: 0px; /* Adjust the positioning as needed */
  }
  .post-disabled, .post-send {
    font-size: 10px;
  }
  .popup {
    width: 100%;
  }
  .popup-inner {
    width: 90%;
    height: 90vh;
  }
  .inputCard {
    height: calc(70vh - 50vw);
  }
  .memeCard {
    width: 50vw;
    height: 50vw;
  }
  .memeCard-content {
    height: 100%;
    width: 100%;
  }
  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-preview {
    position:relative;
    width: 100%;
    height: 100%;
  }
  .topic-header-container {
    width: 75%;
  }
  .topic-headline {
    font-size: 1em;
  }
  .filter-icon-button img {
    height: 1em; /* Adjust based on your icon size */
    width: auto;
  }
  .materialContainer {
    padding-left: 0;
    padding-right: 0;
  }
  .article-container {
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
  }
  .articleDetails {
    width: 95%;
  }
  .contentWrapper {
    flex-direction: column;
  }
  .linkPreviewContainer {
    width: 100%;
  }
  .graphContainer {
    width: 100%;
  }
  .row {
    flex-direction: column;
    align-items: center;
  }
  .column-sm, .column-bg {
    width: 100%;
    margin-top: 4px;;
  }
  .imageLink a {
    width: 100%;
    padding-bottom: 35%;
  }
  .text-row {
    min-height: 180px;
  }
  .card-back-content {
    min-height: 180px;
  }
}

@media screen and (min-width: 701px) {
  .text-row {
    height: 140px;
  }
  .card-back-content {
    min-height: 140px;
  }
}

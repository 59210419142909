
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}



.cta-button {
    background-color: #ff6b6b;
    color: #fff;
    border: none;
    font-size: 16px;
    border-radius: 4px;
    margin-top: 8px;
    padding: 8px 16px;
    margin-bottom: 8px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s;
  }

.cta-button:hover {
    background-color: #ff4f4f;
}

/* Hero section */
.hero {
    padding: 10px 0 70px 0;
    text-align: center;
}

.hero h1 {
    font-size: 42px;
    margin-bottom: 14px;
}

.hero p {
    font-size: 16px;
    margin-bottom: 15px;
}


.video-container {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.video-container video {
    display: block;
    max-width: 100%;
    height: auto;
}

/* Features section */
.features {
    padding: 30px 0px 70px 0;
    text-align: center;
}

.features h2 {
    font-size: 36px;
    margin-bottom: 30px;
}

.feature-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/* Benefits section */
.benefits {
    padding: 30px 0px 70px 0;
    text-align: center;
}

.benefits-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.benefits h2 {
    font-size: 36px;
    margin-bottom: 30px;
}

.feature-box {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 40px;
    margin: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: transform 0.3s ease;
    position: relative; /* Add this line */
    backdrop-filter: blur(10px);

}

.feature-box:hover {
    transform: translateY(-10px);
}

.feature-box img {
    height: 220px;
    margin-bottom: 20px;
    position: relative; /* Add this line */
    border-radius: 5px;

    z-index: 1; /* Add this line */
}

.feature-box::before {
    content: '';
    position: absolute;
    width: 220px;
    border-radius: 5px;
    height: 220px; /* Adjust this value to match the height of the image */
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 2; /* Add this line */
}

.feature-box h3 {
    font-size: 24px;
    margin-bottom: 10px;
    position: relative; /* Add this line */
}

.feature-box p {
    flex-grow: 1;
    font-size: 16px;
    position: relative; /* Add this line */
    text-align: center;
}
.preview-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background: rgba(0, 0, 0, 0.8);
    padding: 1rem;
    border-radius: 8px;
    max-width: 90vw;
    max-height: 90vh;
    pointer-events: none; /* This prevents the modal from intercepting hover events */
}

.preview-modal img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 85vh;
    object-fit: contain;
}

/* CTA section */
.cta {
    padding: 50px 0;
    text-align: center;
}

.cta h2 {
    font-size: 36px;
    margin-bottom: 40px;
}
@media (max-width: 700px) {
    
    .hero {
        padding: 10px 0 70px 0;
    }
    .hero h1 {
        font-size: 20px;
        margin-bottom: 14px;
    }
}
@media (min-width: 701px) {
    .benefits {
        width: 1400px;
        max-width: 100vw;
        margin-left: auto;
        margin-right: auto;
    }
    .features {
        width: 1400px;
        max-width: 100vw;
        margin-left: auto;
        margin-right: auto;
    }
    
}
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
  }
  
  .popup-inner {
  }
  
  .popup-content {
    width: 100%;
    overflow-y: auto;
  }
  
  .content-type-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .materialCardCockpit {
    aspect-ratio: 1 / 1;
    background-size: cover;
    background-position: center;
  }
  
.admin-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; /* This ensures that the container will be at least the height of the viewport */
  padding: 20px;
  gap: 50px;
}

.new-topic-form-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px; /* Additional spacing from the topics table */
}

.new-content-type-form-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px; /* Additional spacing from the content-type table */
}

.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.form-group label {
  min-width: 100px; /* Adjust as needed */
  max-width: 50%;

}

.form-group input,
.form-group select {
  flex: 1; /* This makes the input take up the remaining space */
  margin-left: 10px; /* Adjust as needed */
  max-width: 50%;
}


.admin-profile h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.admin-profile form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.admin-profile label {
  font-weight: 600;
  margin-bottom: 5px;
}

.admin-profile input[type="text"],
.admin-profile select {
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
}

.admin-profile input[type="text"]:focus,
.admin-profile select:focus {
  border-color: #007bff;
}

.admin-profile button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.admin-profile button:hover {
  background-color: #0056b3;
}

.admin-profile button:disabled {
  background-color: #ccc;
}

/* Table styles */
.admin-topics-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.admin-topics-table thead {
  background-color: #f7f7f7;
}

.admin-topics-table th,
.admin-topics-table td {
  text-align: left;
  padding: 12px 15px;
  border-bottom: 1px solid #e0e0e0;
}

.admin-topics-table th {
  color: #333;
  font-weight: 600;
}

.admin-topics-table tr:last-child td {
  border-bottom: none;
}

.admin-topics-table tbody tr:hover {
  background-color: #f0f0f0;
}

.admin-topics-table .actions-cell {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.admin-content-types-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.admin-content-types-table thead {
  background-color: #f7f7f7;
}

.admin-content-types-table th,
.admin-content-types-table td {
  text-align: left;
  padding: 12px 15px;
  border-bottom: 1px solid #e0e0e0;
}

.admin-content-types-table th {
  color: #333;
  font-weight: 600;
}

.admin-content-types-table tr:last-child td {
  border-bottom: none;
}

.admin-content-types-table tbody tr:hover {
  background-color: #f0f0f0;
}

.admin-content-types-table .actions-cell {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.coupons-section {
  margin-top: 20px;
  background-color: #f9f9f9; /* Light grey background */
  padding: 20px;
  border-radius: 4px;
}

.section-heading, .section-subheading {
  color: #333; /* Dark grey text */
}

.create-coupon-form, .coupons-table {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 10px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  color: #666; /* Medium grey text */
}

.form-input {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ddd; /* Light grey border */
  border-radius: 4px;
  box-sizing: border-box; /* Make sure padding doesn't affect overall width */
}

.submit-btn {
  background-color: #4CAF50; /* Green background */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #45a049; /* Darker green on hover */
}

.coupons-table {
  width: 100%;
  border-collapse: collapse;
}

.coupons-table th, .coupons-table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd; /* Light grey border for rows */
}

/* Zebra striping for table rows */
.coupons-table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.admin-profile {
  padding: 20px;
}

.new-content-type-form-container,.new-topic-form-container, .user-role-section, .create-role-section, .coupons-section {
  margin-bottom: 40px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group button {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.admin-topics-table,
.admin-content-types-table,
.users-table,
.coupons-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.admin-topics-table th,
.admin-content-types-table th,
.users-table th,
.coupons-table th,
.admin-topics-table td,
.admin-content-types-table td,
.users-table td,
.coupons-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.admin-topics-table th,
.admin-content-types-table th,
.users-table th,
.coupons-table th {
  background-color: #f2f2f2;
}

.admin-topics-table tr:nth-child(even),
.admin-content-types-table tr:nth-child(even),
.users-table tr:nth-child(even),
.coupons-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.admin-topics-table tr:nth-child(odd),
.admin-content-types-table tr:nth-child(odd),
.users-table tr:nth-child(odd),
.coupons-table tr:nth-child(odd) {
  background-color: #ddd;
}

.actions-cell {
  display: flex;
  gap: 10px;
}

.editable-field {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.section-heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.section-subheading {
  font-size: 20px;
  margin-top: 20px;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #45a049;
}


/* Responsive table */
@media (max-width: 768px) {
  .admin-topics-table,
  .admin-topics-table thead,
  .admin-topics-table tbody,
  .admin-topics-table th,
  .admin-topics-table td,
  .admin-topics-table tr {
    display: block;
  }

  .admin-topics-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .admin-topics-table tr {
    margin-bottom: 15px;
  }

  .admin-topics-table td {
    /* Make table cells look like cards on narrow screens */
    display: block;
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .admin-topics-table td:before {
    /* Now like a table header */
    content: attr(data-label);
    position: absolute;
    left: 10px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: 600;
    color: #333;
  }

  .admin-topics-table .actions-cell {
    padding-left: 10px;
    text-align: left;
  }
}

/* Feedback messages styles */
.feedback-message {
  margin-left: 10px;
  font-size: 0.9em;
}

.feedback-message.success {
  color: #28a745; /* Bootstrap success color */
}

.feedback-message.error {
  color: #dc3545; /* Bootstrap error color */
}
/* Feedback messages continued */
.feedback-message.info {
  color: #17a2b8; /* Bootstrap info color */
}

/* Button styles */
.update-button,
.aggregate-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.update-button {
  background-color: #f0ad4e; /* Bootstrap warning color for update */
}

.update-button:hover {
  background-color: #ec971f; /* Darker shade for hover state */
}

.aggregate-button {
  background-color: #5bc0de; /* Bootstrap info color for aggregate */
}

.aggregate-button:hover {
  background-color: #31b0d5; /* Darker shade for hover state */
}

/* Spinner/loader style */
.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive design for smaller devices */
@media (max-width: 600px) {
  .admin-profile {
    width: 90%;
    margin: 20px auto;
  }

  .admin-profile form {
    gap: 8px;
  }

  .admin-profile input[type="text"],
  .admin-profile select {
    padding: 8px;
  }

  .admin-profile button {
    padding: 8px 10px;
  }

  .admin-topics-table td:before {
    top: 8px;
    left: 8px;
    padding-right: 5px;
  }
  
  .admin-topics-table .actions-cell {
    padding-left: 8px;
  }
}






.App {
  text-align: center;
  padding: 0px;
  display: 'flex';
  justify-content: 'center';
}


.App-link {
  color: #61dafb;
}

.site-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}

.footer {
  width: 100%;
  background: none;
  text-align: center;
  padding: 25px 0;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer ul li {
  display: inline;
  margin-right: 30px;
}

.header-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 100px;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  a {
    text-decoration: none;
  }
}
.logo {
  height: 100px; /* Adjust based on your logo's aspect ratio */
  width: auto;
  /* Additional styling as needed for alignment */
}
.headline {
  margin: 0;
  flex-grow: 1; 
  color: #333;
  font-size: 4em;
  font-weight: bold;
  text-align: left; 
}

.header-links-container {
  display: flex;
  gap: 100px;
}

.header-links-container a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  transition: color 0.3s;
  position: relative;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  /* Optional: change color on hover for better UX */
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  &:hover:before {
    border-bottom: 1px solid #000;
    width: 100%;
  }
  
}

.active-link {
  border-bottom: 1px solid #000;
}


.content-section {
  position: relative;
  display: flex;
  flex-direction: column;
}

/* Styles for the main content area */
.main-content {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}



.project-description {
  text-align: center; /* Center text alignment */
  font-size: 1.2em; /* Increase font size */
  margin-top: 30px; /* Margin top */
  margin-bottom: 30px; /* Margin bottom */
  padding-left: 20%; /* Padding on the left */
  padding-right: 20%; /* Padding on the right */
  font-weight: bold; 
}



.user-display {
  position: fixed;
  bottom: 10px; /* Adjust as per your layout */
  left: 10px; /* Adjust as per your layout */
  background-color: rgba(255, 255, 255, 0.9); /* Optional: For better visibility */
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  z-index: 1000; /* To ensure it stays on top of other elements */
}

.dropdown-menu {
  position: absolute;
  background: transparent; /* Use body background */
  padding: 10px 0; /* Add some padding */
  z-index: 1; /* Ensure it's above other elements */
  display: block; /* Show dropdown */
  animation: fadeIn 0.3s; /* Fade in animation */
  backdrop-filter: blur(10px);
}

.blurry-bg {
  background: transparent;
  backdrop-filter: blur(10px);
}
.bg-custom-purple {
  background-color: #dad9f3;
}
.border-custom-purple {
  border-color: #dad9f3;
}
.bg-custom-dark-purple {
  background-color: #3700b3;
}
.border-custom-dark-purple {
  border-color: #3700b3;
}
.dropdown-menu div {
  padding: 8px 16px;
  text-align: left;
  cursor: pointer;
  transition: transform 0.2s; /* Smooth transition for hover effect */
}

.dropdown-menu div:hover {
  transform: scale(1.01);
}

.m-10 {
  margin: 10px;
}


/* Keyframes for the fadeIn animation */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media (min-width: 1100px) {
  .feed-link-container {
    min-width: 100px;
  }
}
@media (max-width: 1100px) {
  
}

@media (max-width: 700px) {
  #login {
    background-color: rgba(255, 255, 255, 0.3);
    padding: 5px;
    backdrop-filter: blur(10px);
    border-radius: 5px;
  }

  .project-description {
    padding-top: 10px; /* Padding on the left */
    padding-bottom: 10px; /* Padding on the right */

    padding-left: 10px; /* Padding on the left */
    padding-right: 10px; /* Padding on the right */
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
  
  }
  .footer ul li {
    display: block; /* Stack list items vertically */
    margin-right: 0; /* Remove the horizontal margin */
    margin-bottom: 10px; /* Add some space between the items */
  }
  .header-section {
    padding-right: 30px;;
  }
  .header-container {
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
  .header-links-container {
    margin: auto;
    gap: 50px;
  }
}
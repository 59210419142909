
/* Centered Section Title */
.sectionTitle {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 2em;
    color: #333;
}

/* Material Cards Container */
.section-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 1200px; /* Comfortable width for large screens */
    padding: 20px;
}

/* Material Card Style */
.materialCard {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    position: relative;
    flex-basis: calc(30% - 20px); /* 2 cards per row by default */
    box-sizing: border-box;
    transition: box-shadow 0.3s ease;
    padding-top: calc(30% - 20px); /* Maintain square aspect ratio based on width */
    overflow: hidden; /* Ensures content fits within the card */
    background-size: cover;
    background-position: center;
}

.materialCard:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.materialCard-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent overlay */
}

.materialCard-content h3 {
    font-size: 1.2em;
    color: #333;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin: 0;
    font-weight: bold;
}

.materialCard-content p {
    font-size: 1em; /* Adjust font size for additional text */
    color: #555;
    text-align: center;
}

/* Responsive Design for Material Cards */
@media (max-width: 1200px) {
    .materialCard {
        flex-basis: calc(30% - 20px); /* 2 cards per row */
        padding-top: calc(30% - 20px); /* Adjust for square aspect ratio */
    }
}

@media (max-width: 768px) {
    .materialCard {
        flex-basis: calc(100% - 20px); /* 1 card per row for smaller tablets and large phones */
        padding-top: calc(100% - 20px); /* Adjust for square aspect ratio */
    }
}

@media (max-width: 480px) {
    .section-content {
        padding: 10px;
    }

    .materialCard {
        margin: 10px 0; /* Adjust margins for very small screens */
        padding-top: calc(100% - 20px); /* 1 card per row for small phones */
    }
}

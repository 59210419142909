/* Container for the message and button when no articles or topics are found */
.no-articles {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
  }
  
  .no-articles p {
    font-size: 16px; /* Adjust font size as needed */
    margin-bottom: 20px; /* Space between the message and the button */
    color: #333; /* Adjust text color as needed */
  }
  
  .navigate-profile-btn {
    padding: 10px 20px;
    font-size: 14px; /* Adjust button text size as needed */
    background-color: #007bff; /* Primary button color, adjust as needed */
    color: #ffffff; /* Button text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .navigate-profile-btn:hover {
    background-color: #0056b3; /* Darker shade for hover effect, adjust as needed */
  }
  